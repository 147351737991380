.footer{
    /* position: fixed;
    left: 0; 
    bottom: 0; */
    width: 100%; 
    background-color: #081223;
}
.footer img{
    width: 250px;
    margin: 40px 0 0 200px;
}
.footer hr{
    /* width: calc(100% - 30px); */
    margin: 0 200px;
    border: 1px solid rgba(255,255,255,.25);
}

.footer ul{
    list-style-type: none;
    display: flex;
    margin: 10px 0 40px 160px; 
}

.footer ul li {
    padding-left: 50px;
}
.footer ul li:first-child {
    padding-left: 0px;
}

.footer ul li a {
    text-decoration: none;
}
.footer p{    
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .footer img{
        margin-left:100px;
    }
    .footer hr{
        margin: 0 100px;
    }    
    .footer ul{
        margin-left: 60px; 
    }
}

@media only screen and (max-width: 1000px) {
    .footer img{
        margin-left:20px;
    }
    .footer hr{
        margin: 0 20px;
    }    
    .footer ul{
        margin-left: -20px; 
    }
    .footer ul li:first-child {
        padding-left: 15px;
    }
}
@media only screen and (max-width: 830px) {   
    .footer ul{
        display: block;
        column-count: 2;
        margin-bottom: 0;
        /* height: 101px; */
    }
    .footer ul li {
        padding-left: 15px;
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 475px) {   
    .footer ul li, .footer ul li:first-child {
        padding-left: 0;
    }
}

@media only screen and (max-width: 415px) {   
    .footer ul{
        column-count: 1;
    }
    .footer ul li, .footer ul li:first-child {
        padding-left: 15px;
    }
}