.nav{
    display: inline-block;
    float: right;
    margin-top: 80px; 
    margin-right: 75px;
    font-size: 1.75vw;
    font-weight: bold;
}
.nav a,
.nav a:hover, 
.nav a:visited, .nav a:active {
    color: #fff;
}
.nav ul{
    list-style-type: none;
    display: inline-flex;
    margin: 0;
}
.nav ul li {
    padding-left: 75px;
}
.nav ul li a {
    text-decoration: none;
}
.dropbtn {
    border: none;
}  
.dropdown {
    position: relative;
    display: inline-block;
}  
ul.dropdown-content {
    display: none;
    position: absolute;
    margin-left: -65px;
    font-size: 1.5vw;
    background-color: #0B3453;
    border-radius: 5px;
    z-index: 1;
    padding-inline-start: 0px;
}
ul.dropdown-content li{
    padding: 5px 15px;
}
ul.dropdown-content li:first-child{
    border-bottom: 2px solid #fff;
}
.dropdown-content a {
    text-decoration: none;
    display: block;
}
.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown-content li:hover{
    background-color: #808080;
}

@media only screen and (max-width: 1600px) {
    .nav{
        margin-right: 100px;
        margin-top: 85px;
    } 
    .nav ul li{
        padding-left: 50px;
    }
    ul.dropdown-content li{
        padding-left: 15px;
    }
}

@media only screen and (max-width: 1400px) {
    .nav{
        margin-right: 50px;
    } 
}

@media only screen and (max-width: 1275px) {
    .nav ul li{
        padding-left: 25px;
    }
    ul.dropdown-content li{
        padding-left: 15px;
    }
}
@media only screen and (max-width: 1200px) {
    .nav{
        margin-right: 30px;        
        margin-top: 60px;
    } 
    .nav ul li {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .nav{
        margin-right: 10px;        
        margin-top: 67px;
    } 
    .nav ul li {
        padding-left: 15px;
    }
    .nav ul li:last-child{
        padding-right: 50px;
    }
}

@media only screen and (max-width: 900px) {
    .nav ul li {
        padding-left: 15px;
    }
    .nav ul li:last-child{
        padding-right: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .nav{
        display: block;
        float: none;
        margin-top: 0px;
        margin-right: 0px;
        font-size: 3.5vw;
        font-weight: bold;
        text-align: center;
        background-color: #081223;
    }    
    .nav ul{
        padding: 0 10px;
        margin: 10px 0;
    }
    .nav ul li {
        padding-left: 25px;
    }
    .nav ul li:first-child {
        padding-left: 0px;
    }
    ul.dropdown-content {
        font-size: 3vw;
        margin-left: -65px;
    }
    ul.dropdown-content li:last-child{
        text-align: left;
        padding-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .nav ul li {
        padding-left: 20px;
    }
    ul.dropdown-content {
        margin-left: -50px;
    }
}

@media only screen and (max-width: 535px) {
    .nav{
        font-size: 4vw;
    }
    ul.dropdown-content {
        font-size: 3.5vw
    }
    .nav ul li:last-child{
        padding-right: 0;
    }
}

@media only screen and (max-width: 415px) {
    ul.dropdown-content {
        margin-left: -55px;
    }
}
