body, html {
  margin: 0 0 20px 0;
  color: #fff;
  background-color: #081223;
  background-image: linear-gradient(#081223, #1284C3, #081223);
  background-repeat: no-repeat;
}
body, html, input, textarea{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.viewWrapper{
  background-color: #fff;
  min-height: 795px;
  color: #0A2843;
}
.viewWrapper img{
  width: 100%;
}
.viewContent{
  margin: 0 auto;
  width: 60%;
  padding: 20px;
}
a {
  color: #1284C3;
}
a:hover {
  color: #D0E7F3;
}
a:visited, a:active {
  color: #88DAED;
}
.submitBtn, .contactform button{
  height: 50px;
  width: 150px;
  font-size: 16pt;
  font-weight: bold;
  background-color: #0B3453;
  color: #fff;
  border-radius: 5px;
}
.submitBtn:active, .contactform button:active{
  background-color: #808080;
  color: #0B3453;
}

@media only screen and (max-width: 1000px) {
  .viewContent{
      width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .viewContent{
      width: 90%;
  }
}
@media only screen and (max-width: 450px) {
  .viewContent{
      width: calc(100% - 30px);
      padding: 0 0 20px 0;
  }
}