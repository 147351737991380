p.cultureImg {
    margin: 0 auto;
    display: block;
    max-width: 400px;
}
.valuesDiv {
    display: flex;
}
p.valuesImg {
    width: 50%;
}
p.valuesText {
    width: 50%;
}
@media only screen and (max-width: 650px) {
.valuesDiv {
    display: block;
}
p.valuesImg {
    width: 100%;
}
p.valuesText {
    width: 100%;
}
}
