.contact h2{
    font-size: 20pt;
}
.contactDetails{
    display: flex;
    justify-content: center; 
    padding-bottom: 30px;
}
.contactDetails p{
    font-size: 16pt;
}
.contactform{
    width: 416px;
    margin: 0 50px;
}
.contactform input, .contactform textarea{
    padding: 5px;
    margin-bottom: 10px;
    border:  3px solid #1284C3;
    font-size: 16pt;
    width: 400px;
}
.contactform textarea{
    height: 400px;
}
.contactform input:hover, .contactform textarea:hover{
    border:  3px solid #88DAED;

}
.contactform input:focus, .contactform textarea:focus{
    border:  3px solid #808080;
    outline: 0;
}
.contactInfo{
    width: 300px;
    margin: 0 50px;
}

@media only screen and (max-width: 1450px) {
    .contact{
        width: 80% !important;
    }
}

@media only screen and (max-width: 1100px) {
    .contact{
        width: 90% !important;
    }
    .contactform{
        margin: 0 25px;
    }
    .contactInfo{
        margin: 0 25px 50px 25px;
    }
}
@media only screen and (max-width: 850px) {
    .contactDetails{
        display: block;
    }
    .contactform{        
        margin: 0 auto 20px auto;
    }
    .contactInfo{        
        margin: 0 auto 20px auto;
        width: 416px;
    }
  }
@media only screen and (max-width: 650px) {
    .contactform input, .contactform textarea{
        width: 263px;
    }
    .contactform textarea{
        height: 260px;
    }
    .contactform, .contactInfo{
        width: 280px;
        margin: 0 auto;
    }
  }