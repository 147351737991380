.header{
  display: inline-block;
  padding: 50px 10px 50px 50px;
}
.header img{
  height: 100px;
}

@media only screen and (max-width: 1200px) {
  .header{
    padding: 30px 10px 30px 30px;
  }
  .header img{
    height: 90px;
  }
}
@media only screen and (max-width: 1000px) {
  .header{
    padding: 40px 10px 40px 40px;
  }
  .header img{
    height: 70px;
  }
}

@media only screen and (max-width: 800px) {
  .header{
    display: block;
    padding: 40px 10px 30px 40px;
  }
  .header img{
    height: 100px;
  }
}

@media only screen and (max-width: 525px) {
  .header img{    
    height: 65px;
  }
}

@media only screen and (max-width: 325px) {
  .header img{    
    height: 58px;
  }
}